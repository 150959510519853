import { PUBLIC_ENV_ASSETS_DOMAIN, PUBLIC_URL, PUBLIC_ENV_ASSETS_PATH } from "Utils/EnvironmentVars";

const ASSETS_DOMAIN = PUBLIC_ENV_ASSETS_DOMAIN || PUBLIC_URL || "";
const ASSETS_PATH = PUBLIC_ENV_ASSETS_PATH || "";

const joinParts = (...parts: string[]): string => parts.filter(Boolean).join("/");
const ensureProtocolOrRoot = (url: string): string => {
    if (!url) {
        return "";
    }
    return url.match(/^https?:/i) ? url : `/${url}`;
};

const ASSETS_PREFIX = ensureProtocolOrRoot(joinParts(ASSETS_DOMAIN, ASSETS_PATH));

export const asset = (assetName: string): string => `${ASSETS_PREFIX}/${assetName}`;

export const icon = (iconName: string): string => asset(`icons/${iconName}.svg`);

/**
 * Fetches the full path for a file  in /public/images
 *
 * @param imageName ex: someFileName.svg
 */
export const image = (imageName: string): string => asset(`images/${imageName}`);
