import { useMemo } from "react";

import { useSelector } from "react-redux";

import {
    selectAgentGmailIntegration,
    selectAgentOutlookIntegration,
    selectAgentEmail,
    selectAgentInformation,
    AgentInformationState,
    EmailIntegration,
    AuthStatusOptions,
} from "Services/state/agent/AgentInformationSlice";

import { AVAILABLE_INTEGRATIONS } from "../constants";

const integrationSelectors: { [key: string]: any } = {
    gmail: selectAgentGmailIntegration,
    outlook: selectAgentOutlookIntegration,
    default: selectAgentEmail,
};

type HookReturn = {
    integrationName: string | undefined;
    agentEmail: string;
    integration?: EmailIntegration;
    integrationIsActive: boolean;
};

export function useGetAgentEmailIntegration(): HookReturn {
    const agentInformation = useSelector(selectAgentInformation);
    const integrationKey = useMemo(
        () =>
            Object.keys(agentInformation).find((key) =>
                AVAILABLE_INTEGRATIONS.some(
                    (i) =>
                        key.includes(`${i}Integration`) &&
                        (agentInformation[key as keyof AgentInformationState] as EmailIntegration[])?.filter(
                            (integration) =>
                                integration.authorizationStatus === AuthStatusOptions.connected ||
                                integration.authorizationStatus === AuthStatusOptions.disconnected
                        ).length > 0
                )
            ),
        [agentInformation]
    );

    const integrationName = integrationKey?.replace(/Integrations?/g, "");
    const agentEmail: any = useSelector(
        integrationName ? integrationSelectors[integrationName] : integrationSelectors.default
    );
    const integration = integrationKey
        ? Array.from(
              (agentInformation[integrationKey as keyof AgentInformationState] as EmailIntegration[]) || []
          ).sort((a, b) => {
              const dateA = new Date(a.createdAt).getTime();
              const dateB = new Date(b.createdAt).getTime();

              if (dateA > dateB) {
                  return -1; // Return -1 if dateA is more recent, to place it before dateB
              } else if (dateA < dateB) {
                  return 1; // Return 1 if dateB is more recent, to place it before dateA
              }
              return 0; // Return 0 if dates are the same
          })[0]
        : undefined;

    return {
        integration,
        integrationIsActive: integration?.authorizationStatus === AuthStatusOptions.connected,
        integrationName: integrationName,
        agentEmail: agentEmail?.emailAddress || agentEmail,
    };
}
