import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getLogger } from "Services/LoggingService";

import { selectBrandAvailableStatuses } from "../brand/selectors";
import { RootState } from "../Storage";

const logger = getLogger("Agent State");

export const AuthStatusOptions = {
    connected: "CONNECTED",
    disconnected: "DISCONNECTED",
    signedOut: "SIGNED_OUT",
} as const;

export type EmailIntegration = {
    id: string;
    authorizationStatus: (typeof AuthStatusOptions)[keyof typeof AuthStatusOptions];
    createdAt: string;
    emailAddress: string;
    signatureHtml?: string;
};

export const USER_CONFERENCE_STATUS = {
    AVAILABLE: "available",
    BUSY: "busy",
    UNREACHABLE: "unreachable",
} as const;

export type UserConferenceStatus = (typeof USER_CONFERENCE_STATUS)[keyof typeof USER_CONFERENCE_STATUS];

export type AgentInformationState = {
    phoneNumber?: string;
    name?: string;
    email?: string;
    workerSid?: string | null;
    workerUri?: string;
    onActiveCall?: boolean;
    brandId: string | null;
    status?: string;
    statusChangedAt?: string;
    attributes?: Record<string, any>;
    preferences?: Record<string, any>;
    gmailIntegration?: Array<EmailIntegration>;
    outlookIntegrations?: Array<EmailIntegration>;
    // eslint-disable-next-line @typescript-eslint/ban-types -- This is necessary for supporting string
    conferenceStatus?: UserConferenceStatus | (string & {});
};

export type AgentActivity = {
    available: boolean;
    name: string;
    id: string;
    startTime: number;
};

export type SetDefaultPhoneNumberPayload = {
    phoneNumber: string;
};

export type SetAgentActivityPayload = {
    status: string;
    statusChangedAt: string;
};

export type SetAgentDataPayload = Pick<
    AgentInformationState,
    | "phoneNumber"
    | "email"
    | "workerSid"
    | "workerUri"
    | "status"
    | "statusChangedAt"
    | "attributes"
    | "name"
    | "gmailIntegration"
    | "outlookIntegrations"
    | "preferences"
    | "conferenceStatus"
>;

export type SetPreferencesPayload = {
    preferences: Record<string, any>;
};

type SetWorkerIdsPayload = {
    workerSid: string;
    workerUri: string;
};

type SetOutlookIntegrationSignaturePayload = {
    id: string;
    signatureHtml: string;
};

const initialState: AgentInformationState = {
    brandId: null,
};

const agentInformationSlice = createSlice({
    name: "agentInformation",
    initialState,
    reducers: {
        setDefaultPhoneNumber: (state: AgentInformationState, action: PayloadAction<SetDefaultPhoneNumberPayload>) => {
            state.phoneNumber = action.payload.phoneNumber;
        },
        setAgentData: (state: AgentInformationState, action: PayloadAction<SetAgentDataPayload>) => {
            return { ...state, ...action.payload };
        },
        setWorkerIds: (state: AgentInformationState, action: PayloadAction<SetWorkerIdsPayload>) => {
            state.workerSid = action.payload.workerSid;
            state.workerUri = action.payload.workerUri;
        },
        setOnActiveCall: (state: AgentInformationState, action: PayloadAction<boolean>) => {
            state.onActiveCall = action.payload;
        },
        setBrandId: (state: AgentInformationState, action: PayloadAction<{ brandId: string }>) => {
            if (!state.brandId || state.brandId == action.payload.brandId) {
                state.brandId = action.payload.brandId;
            } else {
                return { brandId: action.payload.brandId };
            }
        },
        setAgentActivity: (state: AgentInformationState, action: PayloadAction<SetAgentActivityPayload>) => {
            state.status = action.payload.status;
            state.statusChangedAt = action.payload.statusChangedAt;
        },
        setAgentPreferences: (state: AgentInformationState, action: PayloadAction<SetPreferencesPayload>) => {
            state.preferences = action.payload.preferences;
        },
        setOutlookIntegrationSignature: (
            state: AgentInformationState,
            action: PayloadAction<SetOutlookIntegrationSignaturePayload>
        ) => {
            state.outlookIntegrations = (state.outlookIntegrations ?? []).map((integration) => {
                if (integration.id === action.payload.id) {
                    return {
                        ...integration,
                        signatureHtml: action.payload.signatureHtml,
                    };
                }

                return integration;
            });
        },
    },
});

export const {
    setDefaultPhoneNumber,
    setAgentData,
    setWorkerIds,
    setOnActiveCall,
    setBrandId,
    setAgentActivity,
    setAgentPreferences,
    setOutlookIntegrationSignature,
} = agentInformationSlice.actions;

export default agentInformationSlice.reducer;

// selectors
export const selectAgentInformation = (state: RootState): AgentInformationState => state.agentInformation;
// ... rest of your selectors
export const selectAgentName = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.name;
});
export const selectOnActiveCall = createSelector(selectAgentInformation, (agentInfo) => {
    return !!agentInfo.onActiveCall;
});
export const selectWorkerSid = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.workerSid;
});
export const selectAgentEmail = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.email;
});
export const selectAgentBrandId = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.brandId;
});
export const selectAgentQueryParams = createSelector([selectWorkerSid, selectAgentEmail], (twilioWorkerSid, email) => {
    return {
        twilioWorkerSid,
        email,
    };
});

export const selectAgentActivity = createSelector(
    selectAgentInformation,
    selectBrandAvailableStatuses,
    (agentInfo, statuses): AgentActivity => {
        if (!statuses.length) {
            // The brand hasn't been loaded yet.
            return {
                available: false,
                id: "",
                name: "Offline",
                startTime: Date.now(),
            };
        }

        const status = statuses.find((item) => item.name == agentInfo.status);
        if (status) {
            return {
                available: status.available,
                id: status.activitySid,
                name: status.name,
                startTime: agentInfo.statusChangedAt ? new Date(agentInfo.statusChangedAt).getTime() : Date.now(),
            };
        }

        const offlineStatus = statuses.find((item) => !item.available);
        if (offlineStatus) {
            logger.warn(
                `Agent status '${agentInfo.status}' not found in available statuses - using '${offlineStatus.name}' instead`
            );
            return {
                available: false,
                name: offlineStatus.name,
                id: offlineStatus.activitySid,
                startTime: Date.now(),
            };
        }

        logger.warn(
            `Agent status '${agentInfo.status}' not found in available statuses - using default offline status`
        );

        return {
            available: false,
            id: "",
            name: "Offline",
            startTime: Date.now(),
        };
    }
);

// This is currently only used for a single gmail integration
// In the future, we will need to support multiple gmail integrations
// We are sorting the list just to be extra safe and deterministic.
export const selectAgentGmailIntegration = createSelector(selectAgentInformation, (agentInformation) => {
    if (typeof agentInformation.gmailIntegration == "undefined" || agentInformation.gmailIntegration.length === 0) {
        return undefined;
    } else {
        return Array.from(agentInformation.gmailIntegration).sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            if (dateA > dateB) {
                return -1; // Return -1 if dateA is more recent, to place it before dateB
            } else if (dateA < dateB) {
                return 1; // Return 1 if dateB is more recent, to place it before dateA
            }
            return 0; // Return 0 if dates are the same
        })[0];
    }
});

export const selectAgentIsGmailConnected = createSelector(selectAgentGmailIntegration, (gmailIntegration) => {
    return !!gmailIntegration && gmailIntegration?.authorizationStatus === AuthStatusOptions.connected;
});

export const selectAgentIsGmailSignedOut = createSelector(selectAgentGmailIntegration, (gmailIntegration) => {
    return gmailIntegration?.authorizationStatus === AuthStatusOptions.signedOut;
});

export const selectAgentOutlookIntegration = createSelector(selectAgentInformation, (agentInformation) => {
    if (
        typeof agentInformation.outlookIntegrations == "undefined" ||
        agentInformation.outlookIntegrations.length === 0
    ) {
        return undefined;
    } else {
        return (Array.from(agentInformation.outlookIntegrations) || []).sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            if (dateA > dateB) {
                return -1; // Return -1 if dateA is more recent, to place it before dateB
            } else if (dateA < dateB) {
                return 1; // Return 1 if dateB is more recent, to place it before dateA
            }
            return 0; // Return 0 if dates are the same
        })[0];
    }
});

export const selectAgentIsOutlookConnected = createSelector(selectAgentOutlookIntegration, (outlookIntegration) => {
    return !!outlookIntegration && outlookIntegration?.authorizationStatus === AuthStatusOptions.connected;
});

export const selectAgentIsOutlookSignedOut = createSelector(selectAgentOutlookIntegration, (outlookIntegration) => {
    return outlookIntegration?.authorizationStatus === AuthStatusOptions.signedOut;
});

export const selectAgentIntegrationEmail = createSelector(
    [selectAgentEmail, selectAgentGmailIntegration, selectAgentOutlookIntegration],
    (email, gmail, outlook) => {
        return gmail?.emailAddress || outlook?.emailAddress || email || "";
    }
);

export const selectWorkerUri = createSelector(selectAgentInformation, (agentInfo) => agentInfo.workerUri);
export const selectPreferences = createSelector(selectAgentInformation, (agentInfo) => agentInfo.preferences || {});
export const selectAgentPreferredEdge = createSelector(
    selectAgentInformation,
    (agentInfo) => agentInfo.attributes?.preferredEdge
);
export const selectAgentConferenceStatus = createSelector(
    selectAgentInformation,
    (agentInfo) => agentInfo.conferenceStatus
);
