import { NullOr, PROGRESSIVE_DIALER_DISPOSITIONS, RVTask } from "@regal-voice/shared-types";
import camelCase from "lodash/camelCase";

import { CHANNELS_TO_EVALUATE_INBOUND_TASK } from "Pages/agentDesktop/agentInterface/constants";
import { taskSummary } from "Services/ConversationsApiService";
import { deepCaseConversion } from "Services/HelpersService";
import { getLogger } from "Services/LoggingService";
import {
    removeConnectionQualityStatistics,
    selectConnectionQualityStatistics,
} from "Services/state/conferences/ConnectionQualityStateSlice";
import { reduxStore } from "Services/state/Storage";
import { Brand } from "Types/Brand";
import { AttributesCampaign } from "Types/Campaign";

import type { AgentInformationState } from "Services/state/agent/AgentInformationSlice";

export enum Direction {
    outbound = "outbound",
    inbound = "inbound",
}

export type TaskAttributes = RVTask<"unknown">["attributes"] & {
    name: string;
    outboundTo?: string;
    to: string;
    title?: string;
    from?: string;
    contactPhone?: string;
    email?: string;
    regalVoicePhone?: string;
    triggerCall?: boolean;
    autoAccept?: boolean;
    originalTaskSid: string;
    originatingTaskSid: string;
    proxySession?: string;
    campaignInfo?: AttributesCampaign;
    conversationSid?: string;
    conferenceFriendlyId?: string;
    taskName?: string;
};

export enum CallStatusEvent {
    QUEUED = "queued",
    RINGING = "ringing",
    IN_PROGRESS = "in-progress",
    CANCELED = "canceled",
    COMPLETED = "completed",
    NO_ANSWER = "no-answer",
    BUSY = "busy",
    FAILED = "failed",
}

const logger = getLogger("Legacy transfer");

export function hasContactPhone(task: NullOr<RVTask>): boolean {
    return !!(task && getAttributes(task).contactPhone);
}

export function isPendingInboundConferenceTask(task: NullOr<RVTask>): boolean {
    return (
        CHANNELS_TO_EVALUATE_INBOUND_TASK.includes(task?.taskChannelUniqueName ?? "") &&
        task?.attributes.direction == "inbound" &&
        !task?.attributes.triggerCall &&
        task?.status == "pending"
    );
}

export function isPendingConferenceTask(task: NullOr<RVTask>): boolean {
    return CHANNELS_TO_EVALUATE_INBOUND_TASK.includes(task?.taskChannelUniqueName ?? "") && task?.status == "pending";
}

export function isInboundConferenceCall(task: RVTask | undefined): boolean {
    return (
        CHANNELS_TO_EVALUATE_INBOUND_TASK.includes(task?.taskChannelUniqueName ?? "") &&
        task?.attributes.direction == "inbound" &&
        !task?.attributes.triggerCall
    );
}

export function isEarlyCustomerHangUpProgressiveDialCall(task?: Partial<RVTask<"unknown">>): boolean {
    return !!(
        task?.attributes?.taskType === "Progressive Dial" &&
        task?.attributes?.disposition === PROGRESSIVE_DIALER_DISPOSITIONS.NO_CONVERSATION_CUSTOMER_HUNG_UP
    );
}

export function isAcceptedInboundConferenceTask(task: NullOr<RVTask>): boolean {
    return (
        CHANNELS_TO_EVALUATE_INBOUND_TASK.includes(task?.taskChannelUniqueName ?? "") &&
        task?.attributes.direction == "inbound" &&
        !task?.attributes.triggerCall &&
        task.status == "accepted"
    );
}

export function isAcceptedConferenceTask(task: NullOr<RVTask>): boolean {
    return CHANNELS_TO_EVALUATE_INBOUND_TASK.includes(task?.taskChannelUniqueName ?? "") && task?.status == "accepted";
}

export function isConnectedProgressiveDialerTask(task: RVTask | undefined): boolean {
    return !!task && task.attributes.taskType === "Progressive Dial" && task.status === "accepted";
}

export function getAttributes(task?: RVTask): TaskAttributes {
    if (task) {
        const attributes =
            (deepCaseConversion(task.attributes, (key: string) => camelCase(key)) as TaskAttributes) || {};
        attributes.originalTaskSid = attributes.originalTaskSid || task.taskSid;

        return attributes;
    }

    return {} as TaskAttributes;
}

export async function summarizeTask(
    values: {
        task: RVTask;
        brand: Brand;
        disposition: string;
        objections?: Array<string>;
        notes?: string;
        dispositionedBy: "agent" | "system";
        regalVoicePhoneFriendlyName: string;
        agent: AgentInformationState;
    },
    taskSummarySubmitted = false
): Promise<boolean> {
    const { disposition, objections, notes, dispositionedBy, task, brand, regalVoicePhoneFriendlyName, agent } = values;

    if (!task) {
        logger.error("Task is undefined", { brand });
        return false;
    }

    if (!brand) {
        logger.error("Brand is undefined", { task });
        return false;
    }

    const { contactPhone, email, originalTaskSid, proxySession, regalVoicePhone, campaignInfo } = getAttributes(task);
    if (taskSummarySubmitted) {
        logger.warn(`Attempting to resubmit task summary: ${task.sid}`);
        return false;
    }

    if (!contactPhone && !email) {
        return false;
    }

    const audioStatistics = selectConnectionQualityStatistics(reduxStore.getState())[task.taskSid];
    const taskSummaryData = {
        phone: contactPhone,
        email,
        disposition,
        agentID: agent.attributes?.contact_uri,
        agentEmail: agent.email as string,
        objections,
        dispositionedBy,
        notes,
        sid: task.sid,
        taskAttributes: {
            accountSid: brand.accountSid,
            taskAge: task.age,
            taskSid: task.taskSid,
            originalTaskSid,
            regalVoicePhone,
            regalVoicePhoneFriendlyName,
            proxySession,
            campaignInfo,
            taskChannelUniqueName: task.taskChannelUniqueName,
            taskDateCreated: task.createdDate,
            taskPriority: task.priority,
            taskQueueName: task.queueName,
            workflowName: task.workflowName,
            workerAttributes: agent.attributes as Record<string, any>,
            contactPhone: contactPhone,
            email,
            ...task.attributes,
        },
        audioStatistics,
    };

    const response = await taskSummary(taskSummaryData);
    reduxStore.dispatch(removeConnectionQualityStatistics(task.taskSid));
    return response?.success;
}
