// these need to eventually be their own routes
const USER_MANAGEMENT_PATH = "/user-management";
const DATA_MANAGEMENT_PATH = "/data-management";

export const SettingsPaths = {
    root: "/",
    generalSettings: "/general-settings",
    mySettings: "/my-settings",
    agentDesktop: "/agent-desktop",
    // TODO move these to their own routes
    userManagementPages: [
        `${USER_MANAGEMENT_PATH}/users`,
        `${USER_MANAGEMENT_PATH}/teams`,
        `${USER_MANAGEMENT_PATH}/skills`,
    ],
    userManagementEditUser: [`${USER_MANAGEMENT_PATH}/users/new`, `${USER_MANAGEMENT_PATH}/users/:userId`],
    userManagementTeam: `${USER_MANAGEMENT_PATH}/teams/new`,
    userManagementSkills: [`${USER_MANAGEMENT_PATH}/skills/new`, `${USER_MANAGEMENT_PATH}/skills/:skillId`],
    // TODO move these to their own routes
    customDefinition: `${DATA_MANAGEMENT_PATH}/object-types/:objectType`,
    objectTypes: `${DATA_MANAGEMENT_PATH}/object-types`,
    eventManagement: `${DATA_MANAGEMENT_PATH}/events`,
    eventForm: `${DATA_MANAGEMENT_PATH}/events/:eventType/:eventName`,

    externalTransferPhonebook: "/external-transfer-phonebook",
    contactLists: "/contact-lists",
    integrations: "/integrations",
    phoneNumbers: "/phone-numbers",
    recordings: "/recordings",
    ivrs: "/ivrs",
    yourData: "/your-data",
    routingRules: "/routing-rules",
};
