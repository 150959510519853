/**
 * We centralize the retrieval of all the environment variables here.
 * The purpose is for clarity and to make them easy to mock/replace based on ESM vs CJS.
 * This is a horrible workaround while we dual publish. Afterwards it will be straightforward.
 */
export const PUBLIC_ENV_DD_RUM_APP_ID =
    import.meta.env.PUBLIC_ENV_DD_RUM_APP_ID ?? process.env.PUBLIC_ENV_DD_RUM_APP_ID;
export const PUBLIC_ENV_DD_RUM_CLIENT_TOKEN =
    import.meta.env.PUBLIC_ENV_DD_RUM_CLIENT_TOKEN ?? process.env.PUBLIC_ENV_DD_RUM_CLIENT_TOKEN;
export const PUBLIC_ENV_DD_RUM_ENV = import.meta.env.PUBLIC_ENV_DD_RUM_ENV ?? process.env.PUBLIC_ENV_DD_RUM_ENV;
export const PUBLIC_ENV_DD_RUM_VERSION =
    import.meta.env.PUBLIC_ENV_DD_RUM_VERSION ?? process.env.PUBLIC_ENV_DD_RUM_VERSION;
export const PUBLIC_ENV_DD_RUM_TRACING_ORIGINS =
    import.meta.env.PUBLIC_ENV_DD_RUM_TRACING_ORIGINS ?? process.env.PUBLIC_ENV_DD_RUM_TRACING_ORIGINS;
export const PUBLIC_ENV_INTERCOM_APP_ID =
    import.meta.env.PUBLIC_ENV_INTERCOM_APP_ID ?? process.env.PUBLIC_ENV_INTERCOM_APP_ID;
export const PUBLIC_ENV_ZENDESK_KEY = import.meta.env.PUBLIC_ENV_ZENDESK_KEY ?? process.env.PUBLIC_ENV_ZENDESK_KEY;
export const PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID =
    import.meta.env.PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID ?? process.env.PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID;
export const PUBLIC_ENV_DEV_BOX_PREFIX =
    import.meta.env.PUBLIC_ENV_DEV_BOX_PREFIX ?? process.env.PUBLIC_ENV_DEV_BOX_PREFIX ?? "";
export const NODE_ENV = import.meta.env.NODE_ENV ?? process.env.NODE_ENV;
export const PUBLIC_ENV_GOOGLE_SSO_CLIENT_ID =
    import.meta.env.PUBLIC_ENV_GOOGLE_SSO_CLIENT_ID ?? process.env.PUBLIC_ENV_GOOGLE_SSO_CLIENT_ID;
export const PUBLIC_ENV_ASSETS_DOMAIN =
    import.meta.env.PUBLIC_ENV_ASSETS_DOMAIN ?? process.env.PUBLIC_ENV_ASSETS_DOMAIN;
export const PUBLIC_URL = import.meta.env.PUBLIC_URL ?? process.env.PUBLIC_URL;
export const PUBLIC_ENV_ASSETS_PATH = import.meta.env.PUBLIC_ENV_ASSETS_PATH ?? process.env.PUBLIC_ENV_ASSETS_PATH;
