const TASK_PREFERENCES_PATH = "/task-preferences";
const TEMPLATES_PATH = "/templates";

export const ADSettingsPaths = {
    root: "/",
    templates: TEMPLATES_PATH,
    templatesForm: [`${TEMPLATES_PATH}/new`, `${TEMPLATES_PATH}/:templateId`],
    dispositions: "/dispositions",
    tags: "/tags",
    cancellationReasons: "/cancellation-reasons",
    taskPreferences: TASK_PREFERENCES_PATH,
    taskPreferencesForm: `${TASK_PREFERENCES_PATH}/:variant`,
    agentPermissions: "/agent-desktop-permissions",
} as const satisfies Record<string, `/${string}` | Array<`/${string}`>>;
