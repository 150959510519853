import { Form, FormInstance, Input } from "antd";
import { NamePath } from "antd/lib/form/interface";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";

import { Row, Column } from "Components/shared/Flexbox/Flexbox";
import { useValidation } from "Components/shared/FormValidationContext";
import { internalIsDateComparison } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/helpers";
import { conditionalOptions } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/ConditionalNodeOperators";
import { NodeTriggerType } from "Types/Journey";
import { ConditionalType } from "Types/Segment";

import { eventTypeLabel, propertyDefaults } from "./constants";
import { AddButton, RemoveButton } from "./Shared/Buttons";
import { FormSelect } from "./Shared/FormSelect";
import { JoinLabel } from "./Shared/JoinLabel";
import { PropertyContainer } from "./Shared/PropertyContainer";
import { PropertySelect } from "./Shared/PropertySelect";
import { PropertyValue } from "./Shared/PropertyValue";
import { ReadableExpression } from "./Shared/ReadableExpression/ReadableExpression";
import { getGapSize, operatorRequiresListOfValues, operatorRequiresValue } from "./Shared/Utils";

import type { ConditionalOptions } from "./Types";
import type { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";

import styles from "./PropertyList.module.scss";

function getPropertyData(form: FormInstance, prefixPath: NamePath | null, name: number) {
    // make array from prefixPath
    const prefix = Array.isArray(prefixPath) ? prefixPath : prefixPath ? [prefixPath] : [];
    return form.getFieldValue([...prefix, name]) || {};
}

export function PropertyList({
    form,
    conditionalType,
    name,
    prefixPath,
    eventSchema,
    hidden,
    collapsed,
    // expand event group when adding a new property
    expand,
    options,
}: {
    form: FormInstance;
    conditionalType?: ConditionalType | NodeTriggerType;
    name: number[];
    prefixPath: Array<string | number>;
    eventSchema: EventSchemaOptionEntry;
    hidden?: boolean;
    collapsed: boolean;
    // expand event group when adding a new property
    expand: () => void;
    options: ConditionalOptions;
}): JSX.Element {
    const hideAddProperty = hidden || conditionalType === "contactAttribute";
    const hiddenStyle = hidden ? { display: "none" } : {};

    return (
        <Form.List name={[...name, "filters"]}>
            {(fields, { add, remove }) => (
                <Column gap={collapsed ? 2 : getGapSize(options.direction)} style={{ ...hiddenStyle }}>
                    {fields.map((field) => (
                        <Property
                            form={form}
                            key={field.name}
                            conditionalType={conditionalType}
                            name={field.name}
                            prefixPath={[prefixPath, "filters"].flat()}
                            eventSchema={eventSchema}
                            removeField={() => {
                                remove(field.name);
                                form.validateFields();
                            }}
                            hidden={hidden}
                            collapsed={collapsed}
                            options={options}
                        />
                    ))}
                    <AddButton
                        label="Add Property Condition"
                        onClick={() => {
                            add(propertyDefaults);
                            expand();
                        }}
                        type="link"
                        className={styles.addPropertyButton}
                        hidden={hideAddProperty}
                    />
                </Column>
            )}
        </Form.List>
    );
}

function Property({
    form,
    conditionalType,
    name,
    prefixPath,
    eventSchema,
    removeField,
    hidden,
    collapsed,
    options,
}: {
    form: FormInstance;
    conditionalType?: ConditionalType | NodeTriggerType;
    name: number;
    prefixPath: Array<string | number>;
    eventSchema: EventSchemaOptionEntry;
    removeField: (index: number | number[]) => void;
    hidden?: boolean;
    collapsed: boolean;
    options: ConditionalOptions;
}) {
    const { validationEnabled } = useValidation();
    const { propertyName, propertyOperator, propertyValue, dateComparisonType, relativeDateValue } = getPropertyData(
        form,
        prefixPath,
        name
    );

    const propertyType = propertyName ? eventSchema?.[propertyName]?.fieldType : "string";
    const propertyFieldId = propertyName ? eventSchema?.[propertyName]?.fieldId : "";

    const availableOperators = conditionalOptions(conditionalType, propertyName, propertyType, {
        conditionalNodeIsOneOf: true,
    });
    const requiresValue = operatorRequiresValue(propertyOperator);
    const label = conditionalType ? eventTypeLabel[conditionalType] : "Property Name";

    return (
        <>
            <PropertyHeader
                name={name}
                conditionalType={conditionalType}
                hidden={hidden}
                collapsed={collapsed}
                options={options}
                removeField={removeField}
            />
            <PropertyContainer
                direction={options.direction}
                hidden={hidden || collapsed}
                left={
                    <>
                        <PropertySelect
                            label={label}
                            name={[name, "propertyName"]}
                            hidden={hidden}
                            eventSchema={eventSchema}
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        return validationEnabled && !value
                                            ? Promise.reject(new Error(`${label} is required.`))
                                            : Promise.resolve();
                                    },
                                },
                            ]}
                            onChange={(newValue: any) => {
                                const value = cloneDeep(form.getFieldsValue());
                                // set propertyFieldId and propertyType
                                const propertyFieldId = newValue ? eventSchema?.[newValue]?.fieldId : undefined;
                                const propertyType = newValue ? eventSchema?.[newValue]?.fieldType : undefined;
                                set(value, [...prefixPath, name, "propertyFieldId"], propertyFieldId);
                                set(value, [...prefixPath, name, "propertyType"], propertyType);
                                // end set propertyFieldId and propertyType

                                // reset downstream fields
                                set(value, [...prefixPath, name, "propertyOperator"], "isSet");
                                set(value, [...prefixPath, name, "propertyValue"], undefined);
                                set(value, [...prefixPath, name, "relativeDateValue"], undefined);
                                set(value, [...prefixPath, name, "dateComparisonType"], undefined);
                                form.setFieldsValue(value);
                                form.validateFields([
                                    [...prefixPath, name, "propertyOperator"],
                                    [...prefixPath, name, "propertyValue"],
                                    [...prefixPath, name, "relativeDateValue"],
                                    [...prefixPath, name, "dateComparisonType"],
                                ]);
                            }}
                        />
                        <Form.Item name={[name, "propertyFieldId"]} label="propertyFieldId" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name={[name, "propertyType"]} label="propertyType" hidden>
                            <Input />
                        </Form.Item>
                    </>
                }
                center={
                    <FormSelect
                        name={[name, "propertyOperator"]}
                        label="Operator"
                        options={availableOperators}
                        hidden={hidden}
                        dependencies={[[...prefixPath, name, "propertyName"]]}
                        rules={[
                            {
                                validator: async (_, value) => {
                                    return !value && validationEnabled
                                        ? Promise.reject(new Error("Operator required."))
                                        : Promise.resolve();
                                },
                            },
                        ]}
                        onChange={(selectedValue) => {
                            if (!operatorRequiresValue(selectedValue)) {
                                const formValue = cloneDeep(form.getFieldsValue());
                                set(formValue, [...prefixPath, name, "propertyValue"], undefined);
                                set(formValue, [...prefixPath, name, "relativeDateValue"], undefined);
                                set(formValue, [...prefixPath, name, "dateComparisonType"], undefined);
                                form.setFieldsValue(formValue);
                                form.validateFields([
                                    [...prefixPath, name, "propertyValue"],
                                    [...prefixPath, name, "relativeDateValue"],
                                    [...prefixPath, name, "dateComparisonType"],
                                ]);
                            } else if (internalIsDateComparison(selectedValue)) {
                                const formValue = cloneDeep(form.getFieldsValue());
                                // set initial value for dateComparisonType to "specific"
                                const dateComparisonType =
                                    form.getFieldValue([...prefixPath, name, "dateComparisonType"]) || "specific";
                                set(formValue, [...prefixPath, name, "dateComparisonType"], dateComparisonType);
                                form.setFieldsValue(formValue);
                            } else if (operatorRequiresListOfValues(selectedValue)) {
                                const formValue = cloneDeep(form.getFieldsValue());
                                const value = get(formValue, [...prefixPath, name, "propertyValue"]);
                                if (!!value && !Array.isArray(value)) {
                                    set(formValue, [...prefixPath, name, "propertyValue"], [value]);
                                    form.setFieldsValue(formValue);
                                    form.validateFields([[...prefixPath, name, "propertyValue"]]);
                                } else if (!value) {
                                    // reset form propertyValue because it show an empty tag in multiselect mode
                                    set(formValue, [...prefixPath, name, "propertyValue"], undefined);
                                    form.setFieldsValue(formValue);
                                    form.validateFields([[...prefixPath, name, "propertyValue"]]);
                                }
                            } else if (
                                operatorRequiresValue(selectedValue) &&
                                !operatorRequiresListOfValues(selectedValue)
                            ) {
                                const formValue = cloneDeep(form.getFieldsValue());
                                const value = get(formValue, [...prefixPath, name, "propertyValue"]);
                                if (!!value && Array.isArray(value)) {
                                    set(formValue, [...prefixPath, name, "propertyValue"], value?.[0]);
                                    form.setFieldsValue(formValue);
                                    form.validateFields([[...prefixPath, name, "propertyValue"]]);
                                }
                            }
                        }}
                    />
                }
                right={
                    requiresValue ? (
                        <PropertyValue
                            prefixPath={prefixPath}
                            parentPropName={name}
                            propertyType={propertyType}
                            propertyOperator={propertyOperator}
                            isContactCondtional={conditionalType === "contactAttribute"}
                            propertyFieldId={propertyFieldId}
                            propertyValueDisabled={false}
                            dateComparisonType={dateComparisonType}
                            direction={options.direction}
                            hidden={!requiresValue}
                            dependencies={[
                                [...prefixPath, name, "propertyName"],
                                [...prefixPath, name, "propertyOperator"],
                            ]}
                        />
                    ) : undefined
                }
            />
            <ReadableExpression
                hidden={!collapsed}
                property={propertyName}
                operator={propertyOperator}
                value={propertyValue}
                dateComparisonType={dateComparisonType}
                relativeDateValue={relativeDateValue}
            />
        </>
    );
}

function PropertyHeader({
    name,
    conditionalType,
    hidden,
    collapsed,
    options,
    removeField,
}: {
    name: number;
    conditionalType?: ConditionalType | NodeTriggerType;
    hidden?: boolean;
    collapsed: boolean;
    options: ConditionalOptions;
    removeField: (index: number | number[]) => void;
}) {
    return !hidden &&
        !collapsed &&
        conditionalType != "contactAttribute" &&
        !options?.propertyListOptions?.hideWhereRow ? (
        <Row justifyContent="space-between" alignItems="center">
            {name === 0 && <JoinLabel text="WHERE" type="properties" />}
            {name > 0 && !collapsed && <JoinLabel text="AND" type="properties" />}
            {!collapsed && <RemoveButton onClick={() => removeField(name)} />}
        </Row>
    ) : null;
}
