import { useCallback, useMemo } from "react";

import { Form, FormInstance, Input } from "antd";
import { clsx } from "clsx";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import { Column, Row } from "Components/shared/Flexbox/Flexbox";
import { useValidation, ValidationContextProvider } from "Components/shared/FormValidationContext";
import { eventOperators } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/ConditionalNodeOperators";
import { NodeTriggerType } from "Types/Journey";
import { ConditionalType, ConditionEvent, JoinType, NamedConditionalTypes } from "Types/Segment";

import { eventNameLabel, propertyDefaults } from "./constants";
import { PropertyList } from "./PropertyList";
import { CollapseButton, RemoveButton } from "./Shared/Buttons";
import { EventNameSelect } from "./Shared/EventNameSelect";
import { FormList } from "./Shared/FormList";
import { FormSelect } from "./Shared/FormSelect";
import { JoinLabel } from "./Shared/JoinLabel";
import { LastEventFormItem } from "./Shared/LastEventFormItem";
import { PropertyContainer } from "./Shared/PropertyContainer";
import { ReadableCondition } from "./Shared/ReadableExpression/ReadableExpression";
import { useCollapsedItems } from "./Shared/useCollapsedItems";
import { useEventNames } from "./Shared/useEventNames";
import { getGapSize, isValidEventGroup, joinTypeToString } from "./Shared/Utils";

import type { ConditionalOptions } from "./Types";

import styles from "./EventList.module.scss";

export function EventList({
    form,
    name,
    prefixPath,
    data,
    joinType,
    conditionalTypes,
    conditionalOptions,
}: {
    form: FormInstance;
    name: number;
    prefixPath: Array<string | number>;
    data: ConditionEvent[];
    joinType: JoinType;
    conditionalTypes: NamedConditionalTypes;
    conditionalOptions: ConditionalOptions;
}): JSX.Element {
    const { validationEnabled } = useValidation();
    function getDataLength() {
        return data.length;
    }
    function canCollapseItem(itemIndex: number) {
        // set initial value to false when collapse icon/btn is not visible
        return (
            !conditionalOptions?.conditionalListOptions?.hideCollapse &&
            !conditionalOptions.hideEventSelector &&
            isValidEventGroup(data[itemIndex])
        );
    }
    const { collapsedItems, collapseItemHandler, collapseAllItems, addCollapsedItem, removeCollapsedItem } =
        useCollapsedItems(getDataLength, canCollapseItem);

    const formListRules = useMemo(() => {
        return [
            {
                validator: async (_: any, value: ConditionEvent[]) => {
                    if (!validationEnabled) {
                        return Promise.resolve();
                    }
                    // validate each ConditionEvent
                    const validConditionEvents = value.map((conditionEvent) => isValidEventGroup(conditionEvent));
                    if (validConditionEvents.some((valid) => !valid)) {
                        // if there are event groups with invalid data
                        // expand invalid groups
                        const expandGroups = Object.fromEntries(
                            validConditionEvents.map((valid, index) => [index, valid])
                        );
                        collapseItemHandler(expandGroups);
                        return Promise.reject("test");
                    }
                    return Promise.resolve();
                },
            },
        ];
    }, [collapseItemHandler, validationEnabled]);

    const defaultEvent = conditionalOptions.dynamicConditions?.[0].conditions?.[0];
    return (
        <FormList
            name={[name, "conditions"]}
            rules={formListRules}
            direction={conditionalOptions.direction}
            joinType={joinType}
            itemDefaults={defaultEvent}
            listOptions={conditionalOptions.eventListOptions}
            joinSelectProps={{
                name: [name, "joinType"],
                prefix: "Where",
                sufix: "of the following apply:",
            }}
            addItem={(index) => {
                addCollapsedItem(index);
                collapseAllItems();
            }}
        >
            {/* This is Each attribute to append*/}
            {(field, remove, _index, fieldsLength) => (
                <ValidationContextProvider>
                    <EventGroup
                        key={field.name}
                        form={form}
                        name={field.name}
                        prefixPath={[prefixPath, "conditions", field.name].flat()}
                        conditionalTypes={conditionalTypes}
                        remove={() => {
                            removeCollapsedItem(field.name);
                            remove(field.name);
                            form.validateFields();
                        }}
                        data={data[field.name]}
                        showRemove={fieldsLength > 1}
                        joinType={joinType}
                        collapseItem={collapseItemHandler}
                        collapsed={!!collapsedItems[field.name]}
                        conditionalOptions={conditionalOptions}
                    />
                </ValidationContextProvider>
            )}
        </FormList>
    );
}

function EventGroup({
    form,
    name,
    prefixPath,
    data,
    conditionalTypes,
    showRemove,
    remove,
    joinType,
    collapseItem,
    collapsed,
    conditionalOptions,
}: {
    form: FormInstance;
    name: number;
    prefixPath: Array<string | number>;
    data: ConditionEvent;
    conditionalTypes: NamedConditionalTypes;
    showRemove: boolean;
    remove: (index: number | number[]) => void;
    joinType?: JoinType;
    collapseItem: (updatedValue: Record<string, boolean>) => void;
    collapsed: boolean;
    conditionalOptions: ConditionalOptions;
}) {
    const { enableValidation: setValidationEnabled } = useValidation();
    const { conditionalType, eventName, eventOperator = "isSet" } = data || {};
    const filters = data?.filters || [];

    // PropertyList is visible when:
    // - conditionalType is set and eventOperator is "isSet"
    // - OR conditionalType = contactAttribute
    const hasProperties =
        (conditionalType != null && eventOperator === "isSet") || conditionalType === "contactAttribute";
    const hidePropertyList = !hasProperties;

    const hasAtLeastOneFilter =
        hasProperties &&
        filters.some((filter) => Boolean(filter.propertyOperator || filter.propertyName || filter.propertyValue));
    // LastEventOnly Switch is visible when:
    // - there is at least one filter
    // - conditionalType is not contactAttribute
    const hideAtLeastOneFilter = !hasAtLeastOneFilter || conditionalType === "contactAttribute";

    const { eventNames, eventSchema } = useEventNames(
        conditionalType || "",
        eventName || "",
        conditionalOptions.source
    );

    async function toggleCollapsed(collapse: boolean) {
        try {
            if (collapse) {
                setValidationEnabled(true);
                // `await form.validateFields([prefixPath]);` does not work because
                // it marks all fields from event group as invalid
                await form.validateFields();
            }
            collapseItem({ [name]: collapse });
        } finally {
            setValidationEnabled(false);
        }
    }
    const hideCustomFields = conditionalTypes?.find((c) => c.value == conditionalType)?.hideCustomFields;

    return (
        <>
            <Column gap={collapsed ? 2 : getGapSize(conditionalOptions.direction)} style={{ position: "relative" }}>
                {!conditionalOptions.hideEventSelector && (
                    <EventHeader
                        name={name}
                        showRemove={showRemove}
                        remove={remove}
                        toggleCollapsed={toggleCollapsed}
                        joinType={joinType}
                        collapsed={collapsed}
                        conditionalOptions={conditionalOptions}
                    />
                )}
                <EventSelector
                    form={form}
                    name={name}
                    prefixPath={prefixPath}
                    data={data}
                    conditionalTypes={conditionalTypes}
                    eventNames={eventNames}
                    fieldsLength={filters.length}
                    availableOperators={eventOperators}
                    collapsed={collapsed}
                    conditionalOptions={conditionalOptions}
                />
                <PropertyList
                    form={form}
                    conditionalType={conditionalType}
                    name={[name]}
                    prefixPath={prefixPath}
                    eventSchema={eventSchema}
                    hidden={hidePropertyList || hideCustomFields}
                    collapsed={collapsed}
                    expand={() => toggleCollapsed(false)}
                    options={conditionalOptions}
                />
            </Column>
            <PropertyContainer
                left={<LastEventFormItem name={name} />}
                hidden={conditionalOptions.conditionalListOptions?.hideLastEventOnly || hideAtLeastOneFilter}
                direction={conditionalOptions.direction}
            />
        </>
    );
}

function EventHeader({
    name,
    showRemove,
    remove,
    toggleCollapsed,
    joinType,
    collapsed,
    conditionalOptions,
}: {
    name: number;
    showRemove: boolean;
    remove: (index: number | number[]) => void;
    toggleCollapsed: (value: boolean) => void;
    joinType?: JoinType;
    collapsed: boolean;
    conditionalOptions: ConditionalOptions;
}) {
    const joinLabel = joinTypeToString(joinType);
    return (
        <Row
            alignItems="center"
            justifyContent="space-between"
            className={clsx({
                [styles.verticalHeaderContainer]: name === 0 && conditionalOptions.direction === "horizontal",
                [styles.horizontalHeaderContainer]: name === 0 && conditionalOptions.direction === "vertical",
            })}
        >
            {name > 0 ? <JoinLabel text={joinLabel} type="events" /> : <div />}
            <Row
                alignItems="center"
                justifyContent="flex-end"
                className={clsx(styles.buttonContainer, {
                    [styles.first]: name === 0,
                })}
            >
                <div style={{ visibility: showRemove ? "visible" : "hidden" }}>
                    <RemoveButton onClick={() => remove(name)} />
                </div>
                <CollapseButton expanded={!collapsed} onClick={() => toggleCollapsed(!collapsed)} />
            </Row>
        </Row>
    );
}

/**
 * Helper function to generate a rules ffor the event selector
 */
const generateRule = (errorMessage: string, validationEnabled: boolean) => {
    return [
        {
            validator: async (_: unknown, value: string) =>
                validationEnabled && !value ? Promise.reject(new Error(errorMessage)) : Promise.resolve(),
        },
    ];
};

function EventSelector({
    form,
    name,
    prefixPath,
    data,
    conditionalTypes,
    eventNames,
    fieldsLength,
    availableOperators,
    collapsed,
    conditionalOptions,
}: {
    form: FormInstance;
    name: number;
    prefixPath: Array<string | number>;
    data: ConditionEvent;
    conditionalTypes: NamedConditionalTypes;
    eventNames: string[];
    fieldsLength: number;
    availableOperators: any;
    collapsed: boolean;
    conditionalOptions: ConditionalOptions;
}) {
    const { validationEnabled } = useValidation();
    const { conditionalType, eventName, eventOperator } = data || {};
    const hideCustomFields = conditionalTypes?.find((c) => c.value == conditionalType)?.hideCustomFields;
    // show Event Name selector only when conditionalType is set and is not contactAttribute
    const showEventSelector = !hideCustomFields && conditionalType != null && conditionalType !== "contactAttribute";
    const hiddenEventOperator = conditionalOptions.hideEventOperatorSelector;
    const label = conditionalType ? eventNameLabel[conditionalType] : "Event Name";

    const allowCustomEventName = conditionalType == NodeTriggerType.Custom && !!conditionalOptions.allowCustomEventName;

    const validationRules = useMemo(
        () => ({
            conditional: generateRule("Condition type required.", validationEnabled),
            name: generateRule("Event Name is required.", validationEnabled),
            operator: generateRule("Operator required.", validationEnabled),
        }),
        [validationEnabled]
    );

    const handleConditionalSelection = useCallback(
        (conditional: ConditionalType) => {
            const filters = conditional === "contactAttribute" ? [propertyDefaults] : [];
            const value = cloneDeep(form.getFieldsValue());
            // reset filters when event name changes
            // if the conditional type is pageview or addContactToStaticSegment set the event name as same value, since we won't have the eventName selector
            const eventName = [
                NodeTriggerType.AddContactToStaticSegment,
                NodeTriggerType.Pageview,
                NodeTriggerType.Scheduled,
            ].includes(conditional as any)
                ? conditional
                : undefined;
            set(value, [...prefixPath, "eventName"], eventName);
            set(value, [...prefixPath, "eventOperator"], "isSet");
            set(value, [...prefixPath, "filters"], filters);
            form.setFieldsValue(value);
        },
        [form, prefixPath]
    );

    const handleNameSelection = useCallback(() => {
        if (eventName != null) {
            // reset filters when event name changes only if we had a previous value selected
            // eventName contains previous value
            const value = cloneDeep(form.getFieldsValue());
            set(value, [...prefixPath, "eventOperator"], "isSet");
            set(value, [...prefixPath, "filters"], []);
            form.setFieldsValue(value);
        }
    }, [eventName, form, prefixPath]);

    const handleOperatorSelection = useCallback(
        // selected values is a ConditionalNodeOperator Value, but we dont have a type for that.
        // so string is as close as we get.
        (selectedValue: string) => {
            if (selectedValue === "isNotSet") {
                const value = cloneDeep(form.getFieldsValue());
                set(value, [...prefixPath, name, "filters"], []);
                form.setFieldsValue(value);
                form.validateFields([[...prefixPath, name, "filters"]]);
            }
        },
        [form, name, prefixPath]
    );

    return (
        <>
            {conditionalOptions.eventListOptions?.hideConditionSelector && (
                <Form.Item name={[name, "conditionalType"]} hidden={true}>
                    <Input defaultValue={conditionalType} readOnly={true} />
                </Form.Item>
            )}

            {!conditionalOptions.eventListOptions?.hideConditionSelector && (
                <Column gap={getGapSize(conditionalOptions.direction)}>
                    <PropertyContainer
                        direction={conditionalOptions.direction}
                        hidden={collapsed}
                        left={
                            <FormSelect
                                name={[name, "conditionalType"]}
                                label="Condition Type"
                                options={conditionalTypes}
                                dependencies={[prefixPath]}
                                rules={validationRules.conditional}
                                onChange={handleConditionalSelection}
                            />
                        }
                    />

                    {showEventSelector && (
                        <PropertyContainer
                            direction={conditionalOptions.direction}
                            hidden={collapsed}
                            left={
                                <EventNameSelect
                                    label={label}
                                    name={[name, "eventName"]}
                                    dependencies={[
                                        prefixPath,
                                        [...prefixPath, name],
                                        [...prefixPath, name, "conditionalType"],
                                    ]}
                                    rules={validationRules.name}
                                    eventNames={eventNames}
                                    allowCustomEventName={allowCustomEventName}
                                    onChange={handleNameSelection}
                                />
                            }
                            center={
                                !hiddenEventOperator ? (
                                    <FormSelect
                                        name={[name, "eventOperator"]}
                                        label="Operator"
                                        options={availableOperators}
                                        dependencies={[
                                            prefixPath,
                                            [...prefixPath, name],
                                            [...prefixPath, name, "conditionalType"],
                                            [...prefixPath, name, "eventName"],
                                        ]}
                                        rules={validationRules.operator}
                                        onChange={handleOperatorSelection}
                                    />
                                ) : null
                            }
                        />
                    )}
                </Column>
            )}
            <ReadableCondition
                conditionalType={conditionalType}
                eventName={eventName}
                operator={eventOperator}
                hidden={!collapsed}
                fieldsLength={fieldsLength}
            />
        </>
    );
}
