import { datadogRum } from "@datadog/browser-rum";
import { initialize as ldInitialize, LDClient } from "launchdarkly-js-client-sdk";

import { PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID } from "Utils/EnvironmentVars";

const MAC_OS_IDENTIFIER = "Mac OS";
export const isMacOs = navigator.userAgent.indexOf(MAC_OS_IDENTIFIER) >= 0;

const LD_CLIENT_ID = PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID as string;
export const ANONYMOUS_LD_USER = { kind: "user", key: "logged-out", anonymous: true };

type LDUserInfo = Record<string, unknown>;

export const initLDClient = (userInfo: LDUserInfo): LDClient => {
    return ldInitialize(LD_CLIENT_ID, userInfo, {
        bootstrap: "localStorage",
        fetchGoals: false, // used for A/B testing, can be off for now
        inspectors: [
            {
                type: "flag-used",
                name: "dd-inspector",
                method: (key, detail) => {
                    datadogRum.addFeatureFlagEvaluation(key, detail.value);
                },
            },
        ],
    });
};

export const prepareDOM = (): Element => {
    const node = document.getElementById("root");
    if (!node) {
        throw new Error("No root element found");
    }

    document.body.classList.add("regal-voice");
    return node;
};
