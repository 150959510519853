import { AppPagePaths } from "App/routes/paths";
import { SidenavItems } from "Components/shared/SidenavItems/SidenavItems";
import { AgentSettingsPaths } from "Pages/settings/AgentSettings/Pages/paths";
import { useFlags } from "Services/FeatureFlagService";
import { PageSubNavItem } from "Types/Settings";

import { ADSettingsPaths } from "../AgentDesktop/paths";
import { SettingsPaths } from "../paths";

export function SettingsSideNavBar(): JSX.Element {
    const {
        customVoicemailRecordings,
        adminContactAttributes,
        showRoutingRules,
        showUserManagement,
        email: emailVersion,
        ivrPage,
        customObjectsV1,
        multipleContactOwners,
        showEventManagement,
        hasExternalTransferPhonebook,
        hasPreRecordedVmDrops,
        displayAgentDesktopPermissionsPage,
    } = useFlags();

    const showEmail = emailVersion > 0;
    const showAgentSettings = showEmail || hasPreRecordedVmDrops;
    const settings: Array<PageSubNavItem> = [
        { key: "general", title: "General Settings", href: SettingsPaths.generalSettings },
        {
            key: "my-settings",
            title: "My Settings",
            href: SettingsPaths.mySettings,
            children: [
                {
                    key: "my-integrations",
                    title: "Integrations",
                    href: `${SettingsPaths.mySettings}${AgentSettingsPaths.integrations}`,
                    isVisible: showEmail,
                },
                {
                    key: "my-recordings",
                    title: "My Pre-recorded Voicemail Drops",
                    href: `${SettingsPaths.mySettings}${AgentSettingsPaths.recordings}`,
                    isVisible: hasPreRecordedVmDrops,
                },
            ],
            tabs: [
                `${SettingsPaths.mySettings}${AgentSettingsPaths.integrations}`,
                `${SettingsPaths.mySettings}${AgentSettingsPaths.recordings}`,
            ],
            isVisible: showAgentSettings,
        },
        {
            key: "agentDesktop",
            title: "Agent Desktop",
            href: ADSettingsPaths.taskPreferences,
            children: [
                {
                    key: "TaskPreferences",
                    title: "Task Preferences",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.taskPreferences}`,
                    tabs: [
                        `${SettingsPaths.agentDesktop}${ADSettingsPaths.taskPreferences}`,
                        `${SettingsPaths.agentDesktop}${ADSettingsPaths.taskPreferencesForm}`,
                    ],
                },
                {
                    key: "dispositions",
                    title: "Dispositions",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.dispositions}`,
                },
                {
                    key: "tags",
                    title: "Tags",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.tags}`,
                },
                {
                    key: "cancellationReasons",
                    title: "Cancellation Reasons",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.cancellationReasons}`,
                },
                {
                    key: "templates",
                    title: "Templates",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.templates}`,
                    tabs: [
                        `${SettingsPaths.agentDesktop}${ADSettingsPaths.templates}`,
                        `${SettingsPaths.agentDesktop}${ADSettingsPaths.templates}/:templateId`,
                    ],
                },
                {
                    key: "agentPermissions",
                    title: "Agent Desktop Permissions",
                    href: `${SettingsPaths.agentDesktop}${ADSettingsPaths.agentPermissions}`,
                    isVisible: displayAgentDesktopPermissionsPage,
                },
            ],
        },
        {
            key: "userManagement",
            title: "User Management",
            href: SettingsPaths.userManagementPages[0],
            isVisible: showUserManagement,
            // show "User Management" as selected if any of
            // these URLs match the current location
            tabs: [
                ...SettingsPaths.userManagementPages,
                ...SettingsPaths.userManagementEditUser,
                ...SettingsPaths.userManagementTeam,
                ...SettingsPaths.userManagementSkills,
            ],
        },
        {
            key: "externalTransferPhonebook",
            title: "External Transfer Phonebook",
            href: SettingsPaths.externalTransferPhonebook,
            isVisible: hasExternalTransferPhonebook,
        },
        {
            key: "contactLists",
            title: "Contact Lists",
            href: SettingsPaths.contactLists,
            isVisible: multipleContactOwners,
        },
        {
            key: "routingRules",
            title: "Task Routing",
            // todo create a use the routingrules paths
            href: `${SettingsPaths.routingRules}/rules`,
            tabs: [
                `${SettingsPaths.routingRules}/rules`,
                `${SettingsPaths.routingRules}/queues`,
                `${SettingsPaths.routingRules}/queues/new`,
                `${SettingsPaths.routingRules}/queues/:queueId`,
                `${SettingsPaths.routingRules}/attributes`,
            ],
            isVisible: showRoutingRules,
        },
        { key: "phoneNumbers", title: "Active Phone Numbers", href: SettingsPaths.phoneNumbers },
        {
            key: "ivrs",
            title: "IVRs",
            href: SettingsPaths.ivrs,
            isVisible: ivrPage,
            tabs: [SettingsPaths.ivrs, `${SettingsPaths.ivrs}/new`, `${SettingsPaths.ivrs}/:ivrId`],
        },
        {
            key: "recordings",
            title: "Recordings",
            href: SettingsPaths.recordings,
            isVisible: customVoicemailRecordings,
        },

        ...(showEventManagement
            ? [
                  {
                      key: "dataManagement",
                      title: "Data Management",
                      href: customObjectsV1 ? SettingsPaths.objectTypes : SettingsPaths.yourData,
                      isVisible: adminContactAttributes || showEventManagement,
                      children: showEventManagement
                          ? [
                                {
                                    key: "objects",
                                    title: "Objects",
                                    href: customObjectsV1 ? SettingsPaths.objectTypes : SettingsPaths.yourData,
                                    tabs: [
                                        SettingsPaths.yourData,
                                        SettingsPaths.objectTypes,
                                        SettingsPaths.customDefinition,
                                    ],
                                    isVisible: adminContactAttributes,
                                },
                                {
                                    key: "events",
                                    title: "Events",
                                    href: SettingsPaths.eventManagement,
                                    tabs: [SettingsPaths.eventForm],
                                    isVisible: showEventManagement,
                                },
                            ]
                          : [],
                  },
              ]
            : [
                  {
                      key: "dataManagement",
                      title: "Data Management",
                      href: customObjectsV1 ? SettingsPaths.objectTypes : SettingsPaths.yourData,
                      tabs: [SettingsPaths.yourData, SettingsPaths.objectTypes, SettingsPaths.customDefinition],
                      isVisible: adminContactAttributes,
                  },
              ]),
        { key: "integrations", title: "Integrations", href: SettingsPaths.integrations },
    ];

    return <SidenavItems sidenavTitle="Settings" items={settings} basePath={AppPagePaths.settings} />;
}
