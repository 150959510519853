import { getTaskPreference } from "Services/TasksPreferences";

import type { RVTask, TaskAttributesTitle } from "@regal-voice/shared-types";

export const openTaskUrlIfNeeded = (task: RVTask) => {
    const title = task.attributes.title;
    if (!title) {
        return;
    }

    const { enabled } = getTaskPreference({
        name: "tasksAutoOpenUrl",
        taskTitle: title as TaskAttributesTitle,
    });

    if (!enabled) {
        return;
    }

    let { openUrl } = task.attributes;
    if (typeof openUrl !== "string") {
        return;
    }

    if (openUrl.match(/^www\./i)) {
        openUrl = `https://${openUrl}`;
    } else if (!openUrl.match(/^https?:\/\//i)) {
        return;
    }

    window.open(openUrl, "_blank", "noopener");
    window.focus();
};
