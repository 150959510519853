import isArrayLikeObject from "lodash/isArrayLikeObject";
import isNumber from "lodash/isNumber";
import isObjectLike from "lodash/isObjectLike";
import isString from "lodash/isString";
import kebabCase from "lodash/kebabCase";
import omit from "lodash/omit";
import pick from "lodash/pick";
import reduce from "lodash/reduce";

import { ChannelStatsKeys, TotalChannelStatsKeys } from "Types/Agent";

export const URL_REGEX =
    /(?<url>((?<protocol>https?:)\/\/|(?<subdomain>www\.))[a-zA-Z0-9][-a-zA-Z0-9:._]{1,256}\.[a-zA-Z]{1,6}(?<query>[-a-zA-Z0-9()@:;%_\,+.~#?&\/=]*)[^.])(?<sentenceTerminator>\.)?(?<whitespace>\s|$)/gi;

export const EXCLUDE_FROM_OTHER_TASKS_GROUPING = ["count", "voice", "sms", "email"];

export function validateHttp(str: string): string {
    if (!(str?.indexOf("http://") == 0 || str?.indexOf("https://") == 0)) {
        str = str?.replace(/^/, "https://");
    }
    return str;
}

export function isValidLink(value: string): boolean {
    URL_REGEX.lastIndex = 0;
    return !!value && URL_REGEX.test(value);
}

export function formatSlug(value: string) {
    return value
        .replace(/\s+/g, "-")
        .replace(/[^0-9a-zA-Z_-]/g, "")
        .replace(/[-]{2,}/, "-")
        .toLowerCase();
}

export function deepCaseConversion(params: any, conversionFunction: (p: string) => string): typeof params {
    if (!isObjectLike(params) || isString(params) || isNumber(params)) {
        // primitive value, should not be cast
        // there are ways that strings and numbers can appear as objects, so handle for that as well
        return params;
    } else if (isArrayLikeObject(params)) {
        return reduce(
            params,
            (acc, v, k: number) => {
                acc[k] = deepCaseConversion(v, conversionFunction);
                return acc;
            },
            [] as Array<any>
        );
    }
    return reduce(
        params,
        (acc, v, k) => {
            acc[conversionFunction(k)] = deepCaseConversion(v, conversionFunction);
            return acc;
        },
        {} as Record<string, any>
    );
}

export function convertTopLevelAttribute(s: string, convertFn: (p: string) => string): string {
    /*
        For contact attributes, convert top level of field attribute key string to be
        be camel case. Also have to then convert back when actually saving joruney definition
    */
    const attributePath = s?.split(".");
    attributePath?.splice(0, 1, convertFn(attributePath[0])).join(",");
    return attributePath?.join(".");
}

export const getColor = (color: string): string | undefined =>
    typeof getComputedStyle == "undefined"
        ? ""
        : getComputedStyle(document.body)
              .getPropertyValue(`--${kebabCase(color)}`)
              ?.trim();

export async function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const MAX_BACKOFF_MS = 10000;

/**
 * Exponentially backs off with a base of 100ms and a jitter of up to 150ms.
 * Sets a maximum backoff of 10 seconds.
 */
export async function waitForBackoffAndJitter(attempt: number): Promise<void> {
    const backoff = 100 * Math.pow(2, attempt);
    const jitter = Math.floor(Math.random() * 150);
    await sleep(Math.min(backoff + jitter, MAX_BACKOFF_MS));
}

export function alphabeticallySortByProperty(prop: string) {
    return (a: Record<string, any>, b: Record<string, any>): number =>
        a[prop]?.localeCompare(b[prop], undefined, { numeric: true, sensitivity: "base" });
}

export function alphabeticallyReorderBlobsByKey(data: Record<string, any>): Record<string, any> {
    // alphabetical reorders (a-z by default) key-value pairs by keys' string
    return Object.keys(data)
        .sort()
        .reduce(
            (acc, key) => ({
                ...acc,
                [key]: data[key],
            }),
            {}
        );
}

export function immutableSplice<T>(arr: Array<T>, start: number, deleteCount: number, ...items: Array<T>): Array<T> {
    return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

export function formatFriendlyId(friendlyId: string | undefined): string {
    return friendlyId ? `(ID #${friendlyId})` : "";
}

export function removeClientPrefixFromString(input: string): string {
    return input.replace(/^client:/, "");
}

export const _taskChannelDisplayNames: Record<string, string> = {
    sms: "SMS",
    voice: "Calls",
    email: "Emails",
    reminder: "Reminders",
    voicemail: "Voicemails",
};
/**
 * Given task channel name, gives display name for how we refer to that channel in the app.
 *
 * Falls back to whatever channel value was passed in so that we at least show something.
 */
export function getDisplayNameForTaskChannel(channel: string): string {
    return _taskChannelDisplayNames[channel] || channel;
}

export function getTaskCountBasedOnChannel(
    tasksStats: ChannelStatsKeys | TotalChannelStatsKeys
): Record<string, number> {
    const others = Object.values(omit(tasksStats, EXCLUDE_FROM_OTHER_TASKS_GROUPING)).reduce((acc, curr) => {
        acc += parseInt(curr.toString());
        return acc;
    }, 0);

    return {
        ...pick(tasksStats, EXCLUDE_FROM_OTHER_TASKS_GROUPING),
        others,
    };
}

export const validateUrlIsAccessible = async (urlToCheck: string): Promise<boolean> => {
    try {
        const url = new URL(urlToCheck);
        const response = await fetch(url, { method: "HEAD" });
        return response.ok;
    } catch {
        return false;
    }
};

export async function validateUrls(
    url: string,
    fallbackUrl: string | undefined,
    validate: (arg: boolean) => void,
    setActual: (arg: string) => void
): Promise<void> {
    try {
        // Validate the primary URL
        const isUrlValid = !!url && (await validateUrlIsAccessible(url));
        if (isUrlValid) {
            setActual(url);
            validate(true);
            return;
        }

        // If the primary URL is not valid, check the fallback URL
        const isFallbackUrlValid = !!fallbackUrl && (await validateUrlIsAccessible(fallbackUrl));
        if (isFallbackUrlValid) {
            setActual(fallbackUrl);
            validate(true);
            return;
        }
        validate(false);
    } catch {
        validate(false);
    }
}

const lookup = [
    { value: 1e18, digits: 2, symbol: "E" },
    { value: 1e15, digits: 2, symbol: "P" },
    { value: 1e12, digits: 2, symbol: "T" },
    { value: 1e9, digits: 2, symbol: "B" },
    { value: 1e6, digits: 2, symbol: "M" },
    { value: 1e3, digits: 3, symbol: "" },
    { value: 1, digits: 0, symbol: "" },
];

export function numberPrettyFormatter(num: number | undefined, digits: number | undefined = undefined): string {
    if (!num) {
        return "0";
    }
    const item = lookup.find(function (item) {
        return num >= item.value;
    });
    let retNum = item ? `${(num / item.value).toFixed(digits || item.digits)} ${item.symbol}` : "0";
    if (item?.symbol === "") {
        retNum = retNum.replace(".", ",");
    }
    return retNum;
}
