import { getLogger } from "Services/LoggingService";
import { fetch } from "Services/util/NetworkUtilService";

let versionOnPage: string;
const logger = getLogger("App Versions Checker");

function loadVersionOnPage() {
    versionOnPage = document.body.dataset.version || "dev";
}

export async function checkActiveVersion(): Promise<{
    isActive: boolean;
    versionOnPage: string;
    versionOnServer: string;
}> {
    if (!versionOnPage) {
        logger.log("Missing active version");
        loadVersionOnPage();
        logger.log("Reloaded active version", { versionOnPage });
        return new Promise((resolve) => {
            setTimeout(async () => {
                const result = await checkActiveVersion();
                logger.log("Delayed recheck of version", result);
                resolve(result);
            }, 1000);
        });
    }

    const versionData = {
        isActive: true,
        versionOnPage,
        versionOnServer: "",
    };

    try {
        const res = await fetch(`asset-manifest.json?${Date.now()}`);
        const { version: versionOnServer } = await res.json();

        const isActive = versionOnServer === versionOnPage;
        versionData.isActive = isActive;
        versionData.versionOnServer = versionOnServer;

        if (!isActive) {
            logger.log("Detected mismatched versions", versionData);
        }
    } catch (e) {
        logger.warn("Failed to check app version", { err: e });
    }

    return versionData;
}

loadVersionOnPage();
