import { useMemo } from "react";

import { flag$ } from "Application/thirdParty/launchDarkly";
import { useFlags } from "Services/FeatureFlagService";
import { useBrandAdminSetting, getBrandAdminSetting } from "Services/state/brand/BrandAdminSettings";

import type { TaskAttributesTitle, TaskPreferenceName, TaskPreferencesSettingsByName } from "@regal-voice/shared-types";

export type UseTaskPreferenceOptions<T extends TaskPreferenceName> = {
    name: T;
    taskTitle: TaskAttributesTitle;
};

export const useTaskPreference = <T extends TaskPreferenceName>({
    name,
    taskTitle,
}: UseTaskPreferenceOptions<T>): TaskPreferencesSettingsByName[T] => {
    const adminSetting = useBrandAdminSetting({
        name,
        variant: taskTitle,
    });

    return adminSetting as TaskPreferencesSettingsByName[T];
};

export const getTaskPreference = <T extends TaskPreferenceName>({
    name,
    taskTitle,
}: UseTaskPreferenceOptions<T>): TaskPreferencesSettingsByName[T] => {
    const adminSetting = getBrandAdminSetting({
        name,
        variant: taskTitle,
    });

    return adminSetting as TaskPreferencesSettingsByName[T];
};

const normalizeFlagTaskTypes = (flag: string): string[] => flag.split(",").map((item) => item.trim().toLowerCase());

type UseAllowedPreferencesOptions<T extends Record<string, TaskPreferenceName[]>> = {
    base: T;
    prefix?: TaskPreferenceName[];
    suffix?: TaskPreferenceName[];
};

export const useAllowedPreferences = <T extends Record<string, TaskPreferenceName[]>>({
    base,
    prefix = [],
    suffix = [],
}: UseAllowedPreferencesOptions<T>): T => {
    const { autoAcceptByTaskTitle } = useFlags();
    return useMemo(() => {
        const allowed = normalizeFlagTaskTypes(autoAcceptByTaskTitle);
        const allowAll = allowed.includes("all");
        return Object.entries(base).reduce((acc, [key, value]) => {
            const newPreferences = [...prefix, ...value];
            if (allowAll || allowed.includes(key.toLowerCase())) {
                newPreferences.push("callsAutoAccept");
            }

            newPreferences.push(...suffix);
            acc[key as keyof T] = newPreferences as T[keyof T];
            return acc;
        }, {} as T);
    }, [base, autoAcceptByTaskTitle, suffix, prefix]);
};

export const isTaskAllowedToAutoAccept = (taskTitle?: TaskAttributesTitle): boolean => {
    if (!taskTitle) {
        return false;
    }

    const autoAcceptByTaskTitle = flag$("autoAcceptByTaskTitle").getValue();
    const allowed = normalizeFlagTaskTypes(autoAcceptByTaskTitle);
    return allowed.includes("all") || allowed.includes(taskTitle.toLowerCase());
};
