import { PUBLIC_ENV_DEV_BOX_PREFIX } from "Utils/EnvironmentVars";

import { lazyComponent } from "./helpers";
import { AppPagePaths } from "./paths";

const AgentDesktopIndex = lazyComponent(
    "Agent Desktop",
    () => import(/* webpackChunkName: "agentDesktopRouter" */ "Pages/agentDesktop")
);

const AgentsIndex = lazyComponent("Agents page", () => import(/* webpackChunkName: "agentsPage" */ "Pages/agents"));

const CampaignsPageRouter = lazyComponent(
    "Campaigns page",
    () => import(/* webpackChunkName: "campaignsPage" */ "Pages/campaigns/CampaignsRouter")
);

const DashboardPage = lazyComponent(
    "Reporting page",
    () => import(/* webpackChunkName: "reportingPage" */ "Pages/reporting")
);

const AudiencePage = lazyComponent(
    "Audience page",
    () => import(/* webpackChunkName: "audiencePage" */ "Pages/audience")
);

const JourneysIndex = lazyComponent("Journeys page", () => import("Pages/journeys"));
const JourneyEditPage = lazyComponent(
    "Journey edit page",
    () => import("Pages/journeys/JourneyEditPage/JourneyEditPage")
);
const JourneysActiveUsersPage = lazyComponent("Journey active user page", () => import("Pages/journeys/ActiveUsers"));

const RecentActivityPage = lazyComponent(
    "Recent activity page",
    () => import(/* webpackChunkName: "recentActivityPage" */ "Pages/recent-activity")
);

const QueuesIndex = lazyComponent("Queues page", () => import(/* webpackChunkName: "queuesPage" */ "Pages/queues"));

const TasksIndex = lazyComponent("Tasks page", () => import(/* webpackChunkName: "tasksPage" */ "Pages/tasks"));
const RecentTasksIndex = lazyComponent(
    "Recent tasks page",
    () => import(/* webpackChunkName: "recentTasksPage" */ "Pages/recent-tasks")
);

const SegmentsPageRouter = lazyComponent(
    "Segment page",
    () => import(/* webpackChunkName: "segmentPage" */ "Pages/segments")
);

const RegalLiveIndex = lazyComponent(
    "Regal Live",
    () => import(/* webpackChunkName: "regalLivePage" */ "Pages/regal-live")
);

const AIAgentsRoutingPage = lazyComponent(
    "AI Agents",
    () => import(/* webpackChunkName: "aiAgentsPage" */ "Pages/ai-agents/AIAgentsRoutingPage")
);

export const routes = [
    {
        path: AppPagePaths.agentDesktop,
        component: AgentDesktopIndex,
    },
    {
        path: AppPagePaths.agents,
        component: AgentsIndex,
        exact: true,
    },
    {
        path: AppPagePaths.campaigns,
        component: CampaignsPageRouter,
    },
    {
        path: AppPagePaths.dashboard,
        component: DashboardPage,
    },
    {
        path: AppPagePaths.audience,
        component: AudiencePage,
        exact: true,
    },
    {
        path: AppPagePaths.journeys,
        component: JourneysIndex,
        exact: true,
    },
    // @todo: move these into the journeys page and route from there
    {
        path: [`${AppPagePaths.journeys}/:journeyId`, `${AppPagePaths.journeys}/new`],
        component: JourneyEditPage,
        exact: true,
    },
    // @todo: decide if we want to keep this route or not, probbaly move this into the journeys page
    {
        path: ["/journeys-users/:journeyId", "/journeys-users/:journeyId/:nodeId"],
        component: JourneysActiveUsersPage,
        exact: true,
    },
    {
        path: AppPagePaths.recentActivity,
        component: RecentActivityPage,
        exact: true,
    },
    {
        path: AppPagePaths.queues,
        component: QueuesIndex,
        exact: true,
    },
    {
        path: AppPagePaths.segment,
        component: SegmentsPageRouter,
    },
    {
        path: AppPagePaths.tasks,
        component: TasksIndex,
        exact: true,
    },
    {
        path: AppPagePaths.recentTasks,
        component: RecentTasksIndex,
        exact: true,
    },
    {
        path: AppPagePaths.regalLive,
        component: RegalLiveIndex,
        exact: true,
    },
    {
        path: AppPagePaths.aiAgents,
        component: AIAgentsRoutingPage,
        exact: false,
    },
];

export const routerBaseName = PUBLIC_ENV_DEV_BOX_PREFIX;

/**
 * Redirects using window.location.href without breaking devboxess
 * @param path should be prepended with /, ex '/login/foo/bar'
 */
export const hardRedirect = (path: string) => {
    window.location.href = new URL(routerBaseName + path, window.location.origin).href;
};
